import { FC, ComponentProps, useCallback, useRef, Suspense } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    triggerConstants,
} from 'dibs-tracking';
import { ItemTilesCarouselShimmer } from 'dibs-product-tile-carousel/exports/ItemTilesCarouselShimmer';

import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { AB_TEST_EDITORS_PICK, useEditorsPicksVariant } from '../helpers/abTestEditorsPicks';

import { dibsLazyClient } from 'dibs-lazy/exports/dibsLazyClient';
const HpSharedHeroModuleVariant = dibsLazyClient(() =>
    import(/* webpackChunkName: "HpSharedHeroModuleVariant" */ './HpSharedHeroModuleVariant').then(
        m => ({
            default: m.HpSharedHeroModuleVariant,
        })
    )
);
const HpSharedHeroModuleControl = dibsLazyClient(
    () => import(/* webpackChunkName: "HpSharedHeroModuleControl" */ './HpSharedHeroModuleControl')
);

import { HpSharedHeroModule_viewer$key } from './__generated__/HpSharedHeroModule_viewer.graphql';
import { HpSharedHeroModule_user$key } from './__generated__/HpSharedHeroModule_user.graphql';
import { HpSharedHeroModule_componentModule$key } from './__generated__/HpSharedHeroModule_componentModule.graphql';

import dibsCss from 'dibs-css';

const viewerFragment = graphql`
    fragment HpSharedHeroModule_viewer on Viewer {
        ... on Viewer @include(if: $isClient) {
            ...HpSharedHeroModuleControl_viewer @skip(if: $isEditorsPickHpVariant)
            ...HpSharedHeroModuleVariant_viewer @include(if: $isEditorsPickHpVariant)
        }
    }
`;

const userFragment = graphql`
    fragment HpSharedHeroModule_user on User {
        ...HpSharedHeroModuleVariant_user @include(if: $isEditorsPickHpVariant)
    }
`;

const componentModuleFragment = graphql`
    fragment HpSharedHeroModule_componentModule on HeroModule {
        ...HpSharedHeroModuleControl_componentModule @skip(if: $isEditorsPickHpVariant)
        title
        viewMoreLink
    }
`;

export const HpSharedHeroModule: FC<
    Omit<
        ComponentProps<typeof HpSharedHeroModuleControl> &
            ComponentProps<typeof HpSharedHeroModuleVariant>,
        'viewer' | 'user' | 'componentModule'
    > & {
        viewer: HpSharedHeroModule_viewer$key;
        user?: HpSharedHeroModule_user$key | null;
        componentModule: HpSharedHeroModule_componentModule$key;
        isClient: boolean;
    }
> = ({
    viewer: viewerRef,
    user: userRef,
    componentModule: componentModuleRef,
    isClient: afterRefetch,
    ...props
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const componentModule = useFragment(componentModuleFragment, componentModuleRef);
    const user = useFragment(userFragment, userRef);
    const { isMobile } = props;

    const { title, viewMoreLink } = componentModule;
    const isEditorsPickHpVariant = useEditorsPicksVariant();

    const elementRef = useRef<HTMLDivElement>(null);
    const onVisibilityChange: ComponentProps<typeof VisibilityTracker>['onVisibilityChange'] =
        useCallback(({ isVisible }) => {
            if (isVisible) {
                trackAbTestV2Variant(AB_TEST_EDITORS_PICK);
            }
        }, []);

    const onViewMoreClick: ComponentProps<typeof HpSharedHeader>['onViewMoreClick'] =
        useCallback(() => {
            trackEvent({
                eventName: eventNameConstants.EVENT_PRODUCT_INTERACTION,
                interaction_type:
                    interactionTypeConstants.INTERACTION_RECOMMENDATION_VIEW_ALL_CLICKED,
                trigger: triggerConstants.TRIGGER_EDITORS_PICKS,
            });
        }, []);

    const shimmer = isEditorsPickHpVariant ? (
        <ItemTilesCarouselShimmer
            fullWidth={isMobile}
            itemsToShow={isMobile ? 1.5 : 4}
            height={isMobile ? '306px' : '337px'}
            gapSize={isMobile ? 'medium' : 'large'}
            showArrows={!isMobile}
        />
    ) : (
        <ItemTilesCarouselShimmer
            itemsToShow={isMobile ? 1.5 : 4}
            height={isMobile ? '279px' : '307px'}
            gapSize={isMobile ? 'xSmall' : 'small'}
        />
    );

    return (
        <HpSharedModuleContainer addTopGap={!title}>
            <HpSharedHeader
                title={title}
                viewMoreLink={viewMoreLink}
                onViewMoreClick={onViewMoreClick}
                dataTn="editors-picks"
                size="larger"
            />
            <div
                ref={elementRef}
                className={classnames({
                    [dibsCss.mbSmall]: isEditorsPickHpVariant && isMobile,
                    [dibsCss.mbLarge]: isEditorsPickHpVariant && !isMobile,
                })}
            >
                {/* The isEditorsPickHpVariant !== null check prevents server-side rendering. This is necessary because
                 * the variant and control have different shimmers. When cleaning up this test, remove
                 * the check so the shimmer can render on the server side.
                 */}
                {isEditorsPickHpVariant !== null && (
                    <Suspense fallback={shimmer}>
                        {afterRefetch ? (
                            <>
                                <VisibilityTracker
                                    elementRef={elementRef}
                                    onVisibilityChange={onVisibilityChange}
                                />

                                {isEditorsPickHpVariant ? (
                                    <HpSharedHeroModuleVariant
                                        {...props}
                                        viewer={viewer}
                                        user={user}
                                    />
                                ) : (
                                    <HpSharedHeroModuleControl
                                        {...props}
                                        viewer={viewer}
                                        componentModule={componentModule}
                                    />
                                )}
                            </>
                        ) : (
                            shimmer
                        )}
                    </Suspense>
                )}
            </div>
        </HpSharedModuleContainer>
    );
};
